<template>
  <section class="list-wrap">
    <a-spin :spinning="loading" tip="加载中" size="large">
      <ZgyxSearchTablePagination
        ref="searchTablePagination"
        :formConfigs="[]"
        tableRowKey="id"
        :tableColumns="[
          { type: 'input', dataIndex: 'name', title: '名字' },
          { type: 'input', dataIndex: ['project', 'name'], title: '项目名' },
          { type: 'input', dataIndex: ['project', 'nper'], title: '项目期数' },
          { type: 'input', dataIndex: 'typeText', title: '类型' },
          { type: 'input', dataIndex: 'groupNameText', title: '分组' },
          { type: 'input', dataIndex: 'statusText', title: '状态' },
          { type: 'input', dataIndex: 'handleRemark', title: '结果' },
          { type: 'input', dataIndex: 'createTime', title: '创建时间' },
          { type: 'custom', dataIndex: 'action', title: '操作', width: 250 }
        ]"
        :requestFunction="requestFunction"
      >
        <template #action="{ record }">
          <!-- <a-button class="btn" type="link" size="small"> 查看详情 </a-button> -->
          <a-button
            type="primary"
            danger
            class="btn"
            size="small"
            @click="handleCancel(record)"
            v-if="record.status == 0"
            >取消台账</a-button
          >
          <a-button
            class="btn"
            size="small"
            type="primary"
            danger
            @click="handleDownloadItem(record)"
            v-preReClick
            v-if="record.groupName == 'download' && record.status == 3"
          >
            下载
          </a-button>
        </template>
      </ZgyxSearchTablePagination>
    </a-spin>
    <cancelModal :activeId="activeId" v-if="visibleModal" @onCancel="onCancelModal" @onOk="onOkModal"></cancelModal>
  </section>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import { taskSearch, taskDownLoad } from '@src/apis/community';
import { ZgyxSearchTablePagination } from '@src/components';
import { downloadFile, isJSON, downloadFile2 } from '@src/utils';

import cancelModal from './components/cancelModal';
export default {
  name: 'UserManagementsComponent',
  components: { ZgyxSearchTablePagination, cancelModal },
  data() {
    return {
      loading: false,
      taskTypeList: [
        {
          name: '台账',
          value: 100
        }
      ],
      taskStatusList: [
        {
          name: '待处理',
          value: 0
        },
        {
          name: '取消',
          value: 1
        },
        {
          name: '执行中',
          value: 2
        },
        {
          name: '执行成功',
          value: 3
        },
        {
          name: '执行失败',
          value: 4
        }
      ],
      activeId: '',
      visibleModal: false
    };
  },
  computed: {
    ...mapGetters([]),
    requestFunction() {
      return taskSearch;
    }
  },
  mounted() {
    const _this = this;
    _this.$nextTick(() => {});
  },
  methods: {
    ...mapActions(['']),
    /**
     * 取消回调
     */
    onCancelModal() {
      try {
        const _this = this;
        _this.visibleModal = false;
      } catch (error) {
        console.error(error);
      }
    },
    onOkModal() {
      try {
        const _this = this;
        _this.visibleModal = false;
        _this.$refs.searchTablePagination.getList();
      } catch (error) {
        console.error(error);
      }
    },
    async handleCancel(record) {
      const _this = this;
      _this.activeId = record.id;
      _this.visibleModal = true;
    },
    //下载详情
    async handleDownloadItem(record) {
      try {
        const _this = this;
        if (_this.loading) {
          return false;
        }
        _this.loading = true;
        //此处不是blob文件格式，返回url
        const res = await taskDownLoad({ uuid: record.result });
        if (res && res.code === '00000') {
          let url = res.data.url;
          let fileName = res.data.fileName;
          // downloadFile2(
          //   {
          //     url: url,
          //     fileName: fileName
          //   },
          //   () => {
          //     _this.loading = false;
          //   }
          // );
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          _this.loading = false;
        } else {
          _this.loading = false;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import url('~@src/styles/configs/index.less');
@import url('~@src/styles/mixins/index.less');

@import './index.less';
</style>
